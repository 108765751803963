html, body {
	padding: 0;
	margin: 0;
	height: 100%
}
html {
	font-size: 1em;
}
body {
	font-size: 100%;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
// reduce the opacity of placeholder text for the filter field
app-filter .mat-form-field-label {
  opacity: 30%;
}

.mat-dialog-container {
	position: relative;
}

.full-width {  
	width: 100%;
}

.rotate-45 {
	transform: rotate(45deg);
}
